import { Types } from "src/modules/common/types/common";
import styles from "./OurGames.module.scss";
import cl from "classnames";

interface IOurGames {
	games: {
		link: string;
		description: string;
		background: string;
		logo?: string;
		type: "pixudi" | "stars" | "dypians" | "superBall" | "trickShot";
		typeBlockchain?: Types;
	}[];
}

export const OurGames: React.FC<IOurGames> = ({ games }) => {
	return (
		<div className={styles.content} id={"#ourGames"}>
			<div className={styles.title}>OUR GAMES</div>
			<div className={styles.cards}>
				{games.map((game, index) => {
					return (
						<div
							className={cl(
								styles.card,
								{
									[styles.cardCore]:
										game.typeBlockchain === Types.core ||
										game.typeBlockchain == Types.neo,
								},
								{ [styles.card1]: index === 0 },
								{ [styles.card2]: index === 1 },
								{ [styles.card3]: index === 2 }
							)}
							onClick={() => window.open(game.link)}
						>
							<img
								src={game.background}
								alt=""
								className={cl(styles.background, {
									[styles.backgroundCore]:
										game.typeBlockchain === Types.core ||
										game.typeBlockchain == Types.neo,
								})}
							/>
							<div
								className={cl(styles.cardContent, {
									[styles.cardContentCore]:
										game.typeBlockchain === Types.core ||
										game.typeBlockchain == Types.neo,
									[styles.cardContentCorePixudi]:
										(game.typeBlockchain === Types.core ||
											game.typeBlockchain == Types.neo) &&
										game.type === "pixudi",
								})}
							>
								<div className={styles.cardDescription}>{game.description}</div>
								<div className={styles.playNow}>PLAY NOW</div>
							</div>
							{game.logo && <div
								className={cl(styles.logo, {
									[styles.logoCore]:
										game.typeBlockchain === Types.core ||
										game.typeBlockchain == Types.neo,
								})}
							>
								<img
									src={game.logo}
									alt=""
									className={cl(
										{
											[styles.pixudiLogo]: game.type === "pixudi",
										},
										{
											[styles.starsLogo]: game.type === "stars",
										},
										{
											[styles.dypiansLogo]: game.type === "dypians",
										}
									)}
								/>
							</div>}
						</div>
					);
				})}
			</div>
			<div className={styles.titleGame} id={"#gameDevelopers"}>
				HOW WE HELP GAME DEVELOPERS
			</div>
		</div>
	);
};
