import { Route, Routes } from "react-router-dom";
import { MainPageNeo } from "src/pages/MainPageNeo/MainPageNeo";

export function AppRoutes() {
	return (
		<Routes>
			<Route
				path="/"
				element={
					<>
						<MainPageNeo />
					</>
				}
			/>
		</Routes>
	);
}
