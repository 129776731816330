import styles from "./ButtonJoin.module.scss";
import border from "src/modules/common/assets/img/png/border.png";
import borderCore from "src/modules/common/assets/img/png/borderCore.png";
import borderNeo from "src/modules/common/assets/img/png/borderNeo.png";
import { Types } from "../../types/common";

interface IButtonJoin {
	type?: Types;
	dark?: boolean;
}

export const ButtonJoin: React.FC<IButtonJoin> = ({
	type = Types.skale,
	dark = false,
}) => {
	const getLink = (type: Types) => {
		switch (type) {
			case Types.core:
				window.open("https://forms.gle/awcxzCvQnygoBAXB7");
				return;
			case Types.neo:
				window.open("https://forms.gle/u1UBWEr3vkhBckDj6");
				return;
			case Types.skale:
				window.open("https://forms.gle/xesswnXWcqhhZC4g8");
				return;
		}
	};

	const getBorder = (type: Types) => {
		switch (type) {
			case Types.core:
				return borderCore;
			case Types.neo:
				return borderNeo;
			case Types.skale:
				return border;
		}
	};

	return (
		<div className={styles.content} onClick={() => getLink(type)}>
			<img src={getBorder(type)} alt="" className={styles.border} />
			<div
				className={styles.text}
				style={{ color: dark ? "rgba(48, 37, 56, 1)" : "#fff" }}
			>
				{type === Types.neo ? "Join XGuild" : "Join the Guild"}
			</div>
		</div>
	);
};
