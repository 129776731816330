import styles from "./ButtonDiscord.module.scss";
import borderRound from "src/modules/common/assets/img/png/borderRound.png";
import borderRoundCore from "src/modules/common/assets/img/png/borderRoundCore.png";
import borderRoundNeo from "src/modules/common/assets/img/png/borderRoundNeo.png";
import iconX from "src/modules/common/assets/img/svg/iconX.svg";
import iconXNeo from "src/modules/common/assets/img/svg/iconXNeo.svg";
import { Types } from "../../types/common";

interface IButtonJoin {
	type?: Types;
	dark?: boolean;
}

export const ButtonDiscord: React.FC<IButtonJoin> = ({
	type = Types.skale,
	dark = false,
}) => {
	const getLink = (type: Types) => {
		switch (type) {
			case Types.core:
				window.open("https://x.com/GamingOnCore");
				return;
			case Types.neo:
				return;
			case Types.skale:
				window.open("https://x.com/SkaleGuild");
				return;
		}
	};

	const getBorder = (type: Types) => {
		switch (type) {
			case Types.core:
				return borderRoundCore;
			case Types.neo:
				return borderRoundNeo;
			case Types.skale:
				return borderRound;
		}
	};

	return (
		<div className={styles.content} onClick={() => getLink(type)}>
			<img src={getBorder(type)} alt="" className={styles.border} />
			<img src={dark ? iconXNeo : iconX} alt="" />
		</div>
	);
};
