import { ButtonJoin } from "src/modules/common/ui/ButtonJoin/ButtonJoin";
import styles from "./LetsPlay.module.scss";
import cave from "src/modules/letsPlay/assets/img/png/cave.png";
import { ButtonDiscord } from "src/modules/common/ui/ButtonDiscord/ButtonDiscord";
import cl from "classnames";
import { Types } from "src/modules/common/types/common";

interface ILetsPlay {
	type?: Types;
}

export const LetsPlay: React.FC<ILetsPlay> = ({ type = Types.skale }) => {
	return (
		<div className={styles.wrapper}>
			<div
				className={cl(styles.content, {
					[styles.contentCore]: type === Types.core,
				})}
			>
				<div className={styles.leftBlock}>
					<div className={styles.title}>LET’S PLAY!</div>
					<div className={styles.description}>
						{type === Types.core
							? "Join XGuild to shape the future of Web3 gaming together"
							: "Join Skuild to shape the future of Web3 gaming together"}
					</div>
					<div className={styles.btns}>
						<ButtonJoin type={type} />
						<ButtonDiscord type={type} />{" "}
					</div>
				</div>
				{type !== Types.core && (
					<img src={cave} alt="" className={styles.cave} />
				)}
			</div>
		</div>
	);
};
