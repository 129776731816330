import { Header } from "src/modules/common/ui/Header/Header";
import styles from "./MainPageNeo.module.scss";
import { Footer } from "src/modules/common/ui/Footer/Footer";
import { GameDevelopers } from "src/modules/gameDevelopers/ui/GameDevelopers/GameDevelopers";
import { GamingGuild } from "src/modules/gamingGuild/ui/GamingGuild/GamingGuild";
import { LetsPlay } from "src/modules/letsPlay/ui/LetsPlay/LetsPlay";
import { OurGames } from "src/modules/ourGames/ui/OurGames/OurGames";
import { Card } from "src/modules/gamingGuild/ui/Card/Card";
import map from "src/modules/gamingGuild/assets/img/png/map.png";
import pick from "src/modules/gamingGuild/assets/img/png/pick.png";
import neo from "src/modules/gamingGuild/assets/img/png/neo.png";
import neobox from "src/modules/ourGames/assets/img/png/neobox.png";
import worldOfElements from "src/modules/ourGames/assets/img/png/worldOfElements.png";
import pixudiBackground from "src/modules/ourGames/assets/img/png/pixudiBackgroundCore.png";
import pixudiLogo from "src/modules/ourGames/assets/img/png/pixudiLogo.png";
import { Types } from "src/modules/common/types/common";
import { GamingEmpireNeo } from "src/modules/gamingEmpire/ui/GamingEmpireNeo/GamingEmpireNeo";

export const MainPageNeo = () => {
	const cards = [
		<Card
			title={"BUILT ON NEO"}
			description={
				"Enhance your gaming experience with decentralization, scalability, and security provided by NEO Blockchain"
			}
			image={neo}
			className={styles.card1}
			classNameImage={styles.neo}
			type={Types.core}
		/>,
		<Card
			title={"OUR MISSION"}
			description={
				"We aim to build a strong community of game creators and gamers and drive the use of blockchain technology in gaming."
			}
			image={map}
			className={styles.card2}
			classNameImage={styles.map}
			type={Types.core}
		/>,
		<Card
			title={"WHAT WE DO"}
			description={
				"Our guild was created to help developers on Core DAO create, market and promote their games."
			}
			image={pick}
			className={styles.card3}
			classNameImage={styles.pick}
			type={Types.core}
		/>,
	];

	const games: {
		link: string;
		description: string;
		background: string;
		logo?: string;
		type: "pixudi" | "stars" | "dypians" | "superBall" | "trickShot";
		typeBlockchain?: Types;
	}[] = [
		{
			link: "https://game.pixudi.com/",
			description: "MMORPG board game built on blockchain technology.",
			background: pixudiBackground,
			logo: pixudiLogo,
			type: "pixudi",
			typeBlockchain: Types.neo,
		},
		{
			link: "https://neobox.world/",
			description:
				"Exciting games on the Neobox platform. NEOBOX offers users a seamless and secure platform for storing and managing data.",
			background: neobox,
			type: "superBall",
			typeBlockchain: Types.neo,
		},
		{
			link: "https://worldofelements.net/",
			description:
				"World of Elements (in short WoE) is a turn-based single player 2D RPG",
			background: worldOfElements,
			type: "trickShot",
			typeBlockchain: Types.neo,
		},
	];

	return (
		<div className={styles.content}>
			<div className={styles.top}>
				<Header type={Types.neo} />
				<GamingEmpireNeo />
			</div>
			<GamingGuild cards={cards} type={Types.neo} />
			<div className={styles.bottom}>
				<OurGames games={games} />
			</div>
			<GameDevelopers type={Types.neo} />
			<LetsPlay type={Types.neo} />
			<Footer type={Types.neo} />
		</div>
	);
};
