import styles from "./GameDevelopers.module.scss";
import cuteMonster from "src/modules/gameDevelopers/assets/img/png/cuteMonster.png";
import cuteMonsterCore from "src/modules/gameDevelopers/assets/img/png/cuteMonsterCore.png";
import cl from "classnames";
import { Types } from "src/modules/common/types/common";

interface IGameDevelopers {
	type?: Types;
}

export const GameDevelopers: React.FC<IGameDevelopers> = ({
	type = Types.skale,
}) => {
	const getDescription = (type: Types) => {
		switch (type) {
			case Types.core:
				return [
					"Guild membership includes marketing support, such as social media promotion, content creation, and influencer partnerships. Cross-marketing with other guild games can also expand reach and attract diverse audiences​.",
					"We offer development assistance, including tutorials, guides, and resources for building on Core DAO. This support helps streamline development processes, reduce costs, and enhance game quality.",
					"We will be hosting numerous events and tournaments, providing games with additional exposure and engagement opportunities. These events can attract new players and retain existing ones by creating a competitive and social atmosphere",
					"Guild members can access various financial incentives, including grants, play-to-earn scholarships, and staking rewards. These incentives can provide additional funding and resources for game development and marketing​",
				];
			case Types.skale:
				return [
					"Skuild membership includes marketing support, such as social media promotion, content creation, and influencer partnerships. Cross-marketing with other guild games can also expand reach and attract diverse audiences​.",
					"We offer development assistance, including tutorials, guides, and resources for building on the SKALE blockchain. This support helps streamline development processes, reduce costs, and enhance game quality.",
					"We will be hosting numerous events and tournaments, providing games with additional exposure and engagement opportunities. These events can attract new players and retain existing ones by creating a competitive and social atmosphere",
					"Skuild members can access various financial incentives, including grants, play-to-earn scholarships, and staking rewards. These incentives can provide additional funding and resources for game development and marketing​",
				];
			case Types.neo:
				return [
					"XGuild membership includes marketing support, such as social media promotion, content creation, and influencer partnerships. Cross-marketing with other guild games can also expand reach and attract diverse audiences​.",
					"We offer development assistance, including tutorials, guides, and resources for building on the Neo blockchain. This support helps streamline development processes, reduce costs, and enhance game quality.",
					"We will be hosting numerous events and tournaments, providing games with additional exposure and engagement opportunities. These events can attract new players and retain existing ones by creating a competitive and social atmosphere",
					"XGuild members can access various financial incentives, including grants, play-to-earn scholarships, and staking rewards. These incentives can provide additional funding and resources for game development and marketing​",
				];
		}
	};

	const getMonster = (type: Types) => {
		switch (type) {
			case Types.skale:
				return cuteMonster;
			case Types.core:
				return cuteMonsterCore;
			case Types.neo:
				return;
		}
	};

	return (
		<div className={styles.content}>
			<div className={styles.mainContent} style={{ justifyContent: "center" }}>
				<div className={styles.cards}>
					<div className={styles.card}>
						<div className={styles.cardTitle}>CO-MARKETING</div>
						<div className={styles.cardDescription}>
							{getDescription(type)[0]}
						</div>
					</div>
					<div className={styles.card}>
						<div className={styles.cardTitle}>Development Support</div>
						<div className={styles.cardDescription}>
							{getDescription(type)[1]}
						</div>
					</div>
					<div className={styles.card}>
						<div className={styles.cardTitle}>Joint Events and Tournaments</div>
						<div className={styles.cardDescription}>
							{getDescription(type)[2]}
						</div>
					</div>
					<div className={styles.card}>
						<div className={styles.cardTitle}>
							 Financial Incentives and Grants
						</div>
						<div className={styles.cardDescription}>
							{getDescription(type)[3]}
						</div>
					</div>
				</div>
				{type !== Types.neo && (
					<img
						src={getMonster(type)}
						alt=""
						className={cl(styles.cuteMonster, {
							[styles.cuteMonsterCore]: type === Types.core,
						})}
					/>
				)}
			</div>
		</div>
	);
};
