import styles from "./GamingEmpireNeo.module.scss";
import dragonNeo from "src/modules/gamingEmpire/assets/img/png/dragonNeo.png";
import neoTitle from "src/modules/gamingEmpire/assets/img/svg/neoTitle.svg";

export const GamingEmpireNeo = () => {
	return (
		<div className={styles.content}>
			<div className={styles.mainContent}>
				<div className={styles.titleBlock}>
					<div className={styles.title}>
						WE HELP GAME DEVS CREATE AND PROMOTE THEIR GAMES ON
					</div>
					<div className={styles.coreBlock}>
						<img src={neoTitle} alt="" />
					</div>
				</div>
				<img src={dragonNeo} alt="" className={styles.dragon} />
			</div>
		</div>
	);
};
