import styles from "./Header.module.scss";
import logoNeo from "../../assets/img/png/logoNeo.png";
import logoSkale from "../../assets/img/svg/logo.svg";
import { ButtonJoin } from "../ButtonJoin/ButtonJoin";
import menu from "../../assets/img/svg/menu.svg";
import cross from "../../assets/img/svg/cross.svg";
import { useState } from "react";
import cl from "classnames";
import { ButtonDiscord } from "../ButtonDiscord/ButtonDiscord";
import { Link } from "react-router-dom";
import { Types } from "../../types/common";

interface IHeader {
	type?: Types;
}

export const Header: React.FC<IHeader> = ({ type = Types.skale }) => {
	const [isOpenMobile, setIsOpenMobile] = useState<boolean>(false);

	const getLogo = (type: Types) => {
		switch (type) {
			case Types.core:
				return;
			case Types.neo:
				return logoNeo;
			case Types.skale:
				return logoSkale;
		}
	};

	return (
		<div
			className={cl(styles.wrapper, { [styles.mobileWrapper]: isOpenMobile })}
		>
			<div
				className={styles.content}
				style={{
					paddingTop: type === Types.core || type === Types.neo ? "16px" : "0",
				}}
			>
				<div className={styles.menuBlock}>
					{type !== Types.core && <img src={getLogo(type)} alt="" />}
					<div
						className={styles.menu}
						style={{ color: type === Types.neo ? "#302538" : "white" }}
					>
						<Link
							style={{ color: type === Types.neo ? "#302538" : "white" }}
							to={"#gameDevelopers"}
						>
							ABOUT
						</Link>
						<Link
							style={{ color: type === Types.neo ? "#302538" : "white" }}
							to={"#ourGames"}
						>
							GAMES
						</Link>
						<div>PARTNERS</div>
						{/* <div>BLOG</div> */}
					</div>
				</div>
				<div className={styles.btns}>
					<ButtonJoin type={type} dark={type === Types.neo} />
					<ButtonDiscord type={type} dark={type === Types.neo} />
				</div>
				<img
					src={isOpenMobile ? cross : menu}
					alt=""
					className={styles.menuMobile}
					onClick={() => setIsOpenMobile(!isOpenMobile)}
				/>
			</div>
			{isOpenMobile && (
				<div className={styles.mobile}>
					<div
						className={styles.menuBlocksMobile}
						onClick={() => setIsOpenMobile(false)}
					>
						<Link
							to={"#gameDevelopers"}
						>
							ABOUT
						</Link>
						<Link
							to={"#ourGames"}
						>
							GAMES
						</Link>
						<div>PARTNERS</div>
						{/* <div>BLOG</div> */}
					</div>
					<div className={styles.btnsMobile}>
						<ButtonDiscord type={type} />
						<ButtonJoin type={type} />
					</div>
				</div>
			)}
		</div>
	);
};
