import styles from "./Footer.module.scss";
import logoSkale from "src/modules/common/assets/img/svg/logo.svg";
import logoNeo from "src/modules/common/assets/img/png/logoNeo.png";
import iconX from "src/modules/common/assets/img/svg/iconX.svg";
import { Link } from "react-router-dom";
import cl from "classnames";
import { Types } from "../../types/common";

interface IFooter {
	type?: Types;
}

export const Footer: React.FC<IFooter> = ({ type = Types.skale }) => {
	const getLink = (type: Types) => {
		switch (type) {
			case Types.skale:
				window.open("https://forms.gle/xesswnXWcqhhZC4g8");
				return;
			case Types.core:
				window.open("https://forms.gle/awcxzCvQnygoBAXB7");
				return;
			case Types.neo:
				return;
		}
	};

	const getTwitter = (type: Types) => {
		switch (type) {
			case Types.skale:
				window.open("https://x.com/SkaleGuild");
				return;
			case Types.core:
				window.open("https://x.com/GamingOnCore");
				return;
			case Types.neo:
				return;
		}
	};

	const getLogo = (type: Types) => {
		switch (type) {
			case Types.core:
				return;
			case Types.neo:
				return logoNeo;
			case Types.skale:
				return logoSkale;
		}
	};

	return (
		<div className={styles.wrapper}>
			<div
				className={cl(styles.content, {
					[styles.contentCore]: type === Types.core || type === Types.neo,
				})}
			>
				{type !== Types.core && <img src={getLogo(type)} alt="" />}
				<Link to={"#gamingGuild"}>ABOUT</Link>
				<Link to={"#gameDevelopers"}>BENEFITS</Link>
				<img src={iconX} alt="" className={styles.discordMobile} />
				<Link to={"#ourGames"}>GAMES</Link>
				{/* <div>BLOG</div> */}
				<div onClick={() => getLink(type)}>JOIN</div>
				<img
					src={iconX}
					alt=""
					className={styles.discord}
					onClick={() => getTwitter(type)}
				/>
			</div>
		</div>
	);
};
